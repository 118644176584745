// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE
'use strict';


var listen_for_mouseEvt = (function(fn) {
    window.addEventListener('mousemove', evt => {
      fn([evt.clientX, evt.clientY])
    })
  });

var listen_for_touchEvt = (function(fn) {
    window.addEventListener('touchmove', evt => {
      fn([evt.touches[0].clientX, evt.touches[0].clientY])
    })
  });

exports.listen_for_mouseEvt = listen_for_mouseEvt;
exports.listen_for_touchEvt = listen_for_touchEvt;
/* listen_for_mouseEvt Not a pure module */
