// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("Project");

function make(image, company, role, description, achievements, website, $$location, startDate, endDate, highlights, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              return React.createElement("div", {
                          className: "project",
                          id: company
                        }, React.createElement("img", {
                              alt: company,
                              src: image
                            }), React.createElement("div", {
                              className: "project-header"
                            }, React.createElement("div", {
                                  className: "header-group"
                                }, React.createElement("div", {
                                      className: "header-group"
                                    }, React.createElement("span", {
                                          className: "company"
                                        }, company), React.createElement("span", {
                                          className: "location"
                                        }, $$location)), React.createElement("span", {
                                      className: "website"
                                    }, website)), React.createElement("div", {
                                  className: "header-group role-span"
                                }, React.createElement("span", {
                                      className: "role"
                                    }, role), React.createElement("span", {
                                      className: "timespan"
                                    }, startDate, " - ", endDate))), React.createElement("div", {
                              className: "description"
                            }, description), React.createElement("div", {
                              className: "achievements"
                            }, React.createElement("ul", undefined, $$Array.of_list(List.map((function (achievement) {
                                            return React.createElement("li", undefined, achievement);
                                          }), achievements)))), React.createElement("div", {
                              className: "highlights"
                            }, React.createElement("ul", undefined, $$Array.of_list(List.map((function (highlight) {
                                            return React.createElement("li", undefined, highlight);
                                          }), highlights)))));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
