// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE
'use strict';

var Link = require("./link");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function str(prim) {
  return prim;
}

function reducer(action, state) {
  if (action) {
    return /* Update */Block.__(0, [/* record */[/* menuOpen */false]]);
  } else {
    return /* Update */Block.__(0, [/* record */[/* menuOpen */!state[/* menuOpen */0]]]);
  }
}

var component = ReasonReact.reducerComponent("Nav");

function make(_children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* menuOpen */0];
              var menuOpen = match ? "menuOpen" : " ";
              var match$1 = self[/* state */1][/* menuOpen */0];
              var activeClass = match$1 ? "open" : " ";
              var match$2 = self[/* state */1][/* menuOpen */0];
              var activePage = match$2 ? "active" : " ";
              return React.createElement("nav", undefined, React.createElement("div", {
                              id: "Logo"
                            }, ReasonReact.element(undefined, undefined, Link.make("/", (function (_event) {
                                        return Curry._1(self[/* send */3], /* NavClick */1);
                                      }), /* array */[React.createElement("img", {
                                            alt: "tollevin logo",
                                            src: "/images/logo.png"
                                          })]))), React.createElement("div", {
                              className: activeClass,
                              id: "Hamburger",
                              onClick: (function (_event) {
                                  return Curry._1(self[/* send */3], /* ToggleHamburger */0);
                                })
                            }, React.createElement("span", undefined), React.createElement("span", undefined), React.createElement("span", undefined)), React.createElement("div", {
                              className: menuOpen,
                              id: "Nav-link-holder"
                            }, React.createElement("div", {
                                  id: "Alt-logo"
                                }, ReasonReact.element(undefined, undefined, Link.make("/", (function (_event) {
                                            return Curry._1(self[/* send */3], /* NavClick */1);
                                          }), /* array */[React.createElement("img", {
                                                alt: "tollevin logo white",
                                                src: "/images/logo-white.png"
                                              })]))), React.createElement("ul", undefined, React.createElement("li", {
                                      className: activePage
                                    }, ReasonReact.element(undefined, undefined, Link.make("blog", (function (_event) {
                                                return Curry._1(self[/* send */3], /* NavClick */1);
                                              }), /* array */["blog"]))), React.createElement("li", {
                                      className: activePage
                                    }, ReasonReact.element(undefined, undefined, Link.make("work", (function (_event) {
                                                return Curry._1(self[/* send */3], /* NavClick */1);
                                              }), /* array */["work"]))), React.createElement("li", {
                                      className: activePage
                                    }, ReasonReact.element(undefined, undefined, Link.make("shop", (function (_event) {
                                                return Curry._1(self[/* send */3], /* NavClick */1);
                                              }), /* array */["shop"]))), React.createElement("li", {
                                      className: activePage
                                    }, ReasonReact.element(undefined, undefined, Link.make("contact", (function (_event) {
                                                return Curry._1(self[/* send */3], /* NavClick */1);
                                              }), /* array */["contact"]))))));
            }),
          /* initialState */(function (param) {
              return /* record */[/* menuOpen */false];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */reducer,
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.reducer = reducer;
exports.component = component;
exports.make = make;
/* component Not a pure module */
