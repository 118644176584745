// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE
'use strict';


var getById = (function(arg) {
    return document.getElementById(arg)
   });

var createOnBody = (function(width, height) {
    var node = document.createElement('canvas')
    node.width = width
    node.height = height
    document.body.appendChild(node)
    return node
  });

var getImageRGBAData = (function(imageData) {
      return imageData.data
    });

var getColorForCoord = (function(imageData, x, y, width) {
      var data = imageData.data;
      var red = y * (width * 4) + x * 4;
      return [data[red], data[red + 1], data[red + 2], data[red + 3]];
    });

function circle(ctx, x, y, r) {
  ctx.ellipse(x, y, r, r, 0.0, 0.0, 2.0 * (Math.PI));
  return /* () */0;
}

function moveToPos(ctx, param) {
  ctx.moveTo(param[0], param[1]);
  return /* () */0;
}

function lineToPos(ctx, param) {
  ctx.lineTo(param[0], param[1]);
  return /* () */0;
}

function line(ctx, param, param$1) {
  ctx.beginPath();
  ctx.moveTo(param[0], param[1]);
  ctx.lineTo(param$1[0], param$1[1]);
  ctx.stroke();
  return /* () */0;
}

var Ctx = /* module */[
  /* getImageRGBAData */getImageRGBAData,
  /* getColorForCoord */getColorForCoord,
  /* circle */circle,
  /* moveToPos */moveToPos,
  /* lineToPos */lineToPos,
  /* line */line
];

var listen_to_canvas = (function(canvas, fn) {
    canvas.addEventListener('mousemove', evt => {
      fn([evt.clientX, evt.clientY])
    })
  });

exports.getById = getById;
exports.createOnBody = createOnBody;
exports.Ctx = Ctx;
exports.listen_to_canvas = listen_to_canvas;
/* getById Not a pure module */
