// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Canvas = require("../ffi/canvas");
var Document = require("../ffi/document");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var ClapPng = require("../images/clap.png");
var SplashPng = require("../images/splash.png");
var MapCirclePng = require("../images/map-circle.png");
var TuringCirclePng = require("../images/turing-circle.png");

var windowWidth = window.innerWidth;

var windowHeight = window.innerHeight;

function refToContext(canv) {
  if (canv == null) {
    return undefined;
  } else {
    return Caml_option.some(canv.getContext("2d"));
  }
}

function refToImg(imgr) {
  if (imgr == null) {
    return undefined;
  } else {
    return Caml_option.some(imgr);
  }
}

function draw(ctx, img, mouseXY) {
  var canvasHeight = Math.min(windowWidth * 0.7518796992481203 | 0, 1200);
  var canvasWidth = Math.min(windowWidth, 1596);
  var imgWidth = img.naturalWidth;
  var imgHeight = img.naturalHeight;
  var mouseX = List.nth(mouseXY, 0);
  var mouseY = List.nth(mouseXY, 1);
  var scale = function (value, low1, high1, low2, high2) {
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
  };
  var mouseXFactor = scale(canvasWidth - mouseX | 0, 0, canvasWidth, 0.05, 1);
  var mouseYFactor = scale(canvasHeight - mouseY | 0, 0, canvasHeight, 0.05, 1);
  var tileWidth = canvasWidth / imgWidth;
  var tileHeight = canvasHeight / imgHeight;
  ctx.drawImage(img, 0, 0);
  var imgData = ctx.getImageData(0, 0, imgWidth, imgHeight);
  ctx.clearRect(0, 0, canvasWidth, canvasHeight);
  for(var gridX = 0 ,gridX_finish = imgWidth - 1 | 0; gridX <= gridX_finish; ++gridX){
    for(var gridY = 0; gridY <= imgHeight; ++gridY){
      var posX = tileWidth * gridX | 0;
      var posY = tileHeight * gridY | 0;
      var rgbC = Curry._4(Canvas.Ctx[/* getColorForCoord */1], imgData, gridX, gridY, imgWidth);
      var rgbR = Caml_array.caml_array_get(rgbC, 0);
      var rgbG = Caml_array.caml_array_get(rgbC, 1);
      var rgbB = Caml_array.caml_array_get(rgbC, 2);
      var cRgbString = "rgb(" + (String(rgbR) + (", " + (String(rgbG) + (", " + (String(rgbB) + ")")))));
      var greyscale = Math.round(rgbR * 0.222 + rgbG * 0.707 + rgbB * 0.071);
      var w6 = scale(greyscale, 20, 300, 10, 0);
      ctx.fillStyle = cRgbString;
      ctx.beginPath();
      ctx.ellipse(posX, posY, w6 * mouseXFactor, w6 * mouseXFactor, 0, 0, 2 * Math.PI * mouseYFactor);
      ctx.fill();
    }
  }
  return /* () */0;
}

function setCtxRef(r, param) {
  param[/* state */1][/* ctx */0][0] = refToContext(r);
  return /* () */0;
}

function setImgRef(r, param) {
  param[/* state */1][/* img */1][0] = refToImg(r);
  return /* () */0;
}

var component = ReasonReact.reducerComponent("Home");

function make(_children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              var state = self[/* state */1];
              var match = state[/* img */1][0];
              if (match !== undefined) {
                var img = Caml_option.valFromOption(match);
                var match$1 = state[/* ctx */0][0];
                if (match$1 !== undefined) {
                  var ctx = Caml_option.valFromOption(match$1);
                  img.onload = (function (v) {
                      return draw(ctx, img, state[/* mouseXY */2]);
                    });
                  return Curry._1(Document.listen_for_mouseEvt, (function (param) {
                                return draw(ctx, img, /* :: */[
                                            param[0] | 0,
                                            /* :: */[
                                              param[1] | 0,
                                              /* [] */0
                                            ]
                                          ]);
                              }));
                } else {
                  return /* () */0;
                }
              } else {
                return /* () */0;
              }
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return React.createElement("div", {
                          className: "page",
                          id: "Home"
                        }, React.createElement("div", {
                              id: "Lander"
                            }, React.createElement("canvas", {
                                  ref: Curry._1(self[/* handle */0], setCtxRef),
                                  id: "Splash",
                                  height: String(windowWidth * 0.7518796992481203 | 0),
                                  width: String(windowWidth)
                                }), React.createElement("img", {
                                  ref: Curry._1(self[/* handle */0], setImgRef),
                                  id: "SplashImg",
                                  alt: "Me on the beach",
                                  src: SplashPng
                                })), React.createElement("div", {
                              id: "Hello"
                            }, React.createElement("p", undefined, "Hi"), React.createElement("h1", {
                                  className: "intro"
                                }, "I'm Tollevin -", React.createElement("br", undefined), "a digital designer", React.createElement("br", undefined), "based in Brooklyn, NY.")), React.createElement("div", {
                              id: "What"
                            }, React.createElement("div", {
                                  className: "veil"
                                }), React.createElement("div", {
                                  className: "what-text"
                                }, React.createElement("p", undefined, "I make products that promote an equitable future. And lots of tea."), React.createElement("p", undefined, "My aim is to help illuminate and sublimate the systems that oppress people. My interests include tech, tech policy, complex systems, political economic theory, holistic sustainability, and baked goods."), React.createElement("p", undefined, "Apologies if things get a bit illegible below. I'm currently experimenting with mix-blend-mode css :S")), React.createElement("div", {
                                  className: "antiveil"
                                }), React.createElement("div", {
                                  className: "pveil"
                                }), React.createElement("div", {
                                  className: "project-links"
                                }, React.createElement("h2", undefined, "Projects"), React.createElement("div", {
                                      className: "links-holder"
                                    }, React.createElement("a", {
                                          className: "project-preview clapbacks",
                                          href: "https://blacksocialists.us/clapback-chest"
                                        }, React.createElement("div", {
                                              className: "preview-text"
                                            }, React.createElement("h3", undefined, "BSA's Clapback Chest"), React.createElement("p", undefined, "A search engine for all things social and political")), React.createElement("img", {
                                              className: "",
                                              src: ClapPng
                                            })), React.createElement("a", {
                                          className: "project-preview dual-power-map",
                                          href: "https://blacksocialists.us/dual-power-map"
                                        }, React.createElement("div", {
                                              className: "preview-text"
                                            }, React.createElement("h3", undefined, "The Dual Power Map"), React.createElement("p", undefined, "Find and support worker self-directed enterprises in the US.")), React.createElement("img", {
                                              className: "",
                                              src: MapCirclePng
                                            })), React.createElement("a", {
                                          className: "project-preview turing",
                                          href: "/work#Turing"
                                        }, React.createElement("div", {
                                              className: "preview-text"
                                            }, React.createElement("h3", undefined, "Turing.nyc"), React.createElement("p", undefined, "Digital design meets old-school fabrication.")), React.createElement("img", {
                                              className: "",
                                              src: TuringCirclePng
                                            })), React.createElement("a", {
                                          className: "work-link",
                                          href: "/work"
                                        }, "See more..."))), React.createElement("div", {
                                  className: "pantiveil"
                                })));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* ctx : record */[/* contents */undefined],
                      /* img : record */[/* contents */undefined],
                      /* mouseXY : :: */[
                        300,
                        /* :: */[
                          10,
                          /* [] */0
                        ]
                      ]
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              var evt = action[0];
              return /* Update */Block.__(0, [/* record */[
                          /* ctx */state[/* ctx */0],
                          /* img */state[/* img */1],
                          /* mouseXY : :: */[
                            evt.clientX,
                            /* :: */[
                              evt.clientY,
                              /* [] */0
                            ]
                          ]
                        ]]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

var splashAspect = 0.7518796992481203;

exports.windowWidth = windowWidth;
exports.windowHeight = windowHeight;
exports.splashAspect = splashAspect;
exports.refToContext = refToContext;
exports.refToImg = refToImg;
exports.draw = draw;
exports.setCtxRef = setCtxRef;
exports.setImgRef = setImgRef;
exports.component = component;
exports.make = make;
/* windowWidth Not a pure module */
