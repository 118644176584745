// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE
'use strict';

var Nav = require("./components/nav");
var Blog = require("./pages/blog");
var Home = require("./pages/home");
var Shop = require("./pages/shop");
var Work = require("./pages/work");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

((require('./app.css')));

function mapUrlToRoute(url) {
  var match = url[/* path */0];
  if (match) {
    switch (match[0]) {
      case "blog" : 
          if (match[1]) {
            return /* Home */0;
          } else {
            return /* Blog */1;
          }
      case "/" : 
      case "contact" : 
          return /* Home */0;
      case "shop" : 
          if (match[1]) {
            return /* Home */0;
          } else {
            return /* Shop */5;
          }
      case "work" : 
          if (match[1]) {
            return /* Home */0;
          } else {
            return /* Work */3;
          }
      default:
        return /* Home */0;
    }
  } else {
    return /* Home */0;
  }
}

function reducer(action, _state) {
  return /* Update */Block.__(0, [/* record */[/* route */action[0]]]);
}

var component = ReasonReact.reducerComponent("App");

function make(_children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              var watcherID = ReasonReact.Router[/* watchUrl */1]((function (url) {
                      return Curry._1(self[/* send */3], /* ChangeRoute */[mapUrlToRoute(url)]);
                    }));
              return Curry._1(self[/* onUnmount */4], (function (param) {
                            return ReasonReact.Router[/* unwatchUrl */2](watcherID);
                          }));
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var tmp;
              var exit = 0;
              var match = self[/* state */1][/* route */0];
              switch (match) {
                case 1 : 
                    tmp = ReasonReact.element(undefined, undefined, Blog.make(/* array */[]));
                    break;
                case 3 : 
                    tmp = ReasonReact.element(undefined, undefined, Work.make(/* array */[]));
                    break;
                case 5 : 
                    tmp = ReasonReact.element(undefined, undefined, Shop.make(/* array */[]));
                    break;
                case 0 : 
                case 6 : 
                    tmp = ReasonReact.element(undefined, undefined, Home.make(/* array */[]));
                    break;
                case 2 : 
                case 4 : 
                case 7 : 
                    exit = 1;
                    break;
                
              }
              if (exit === 1) {
                throw [
                      Caml_builtin_exceptions.match_failure,
                      /* tuple */[
                        "app.re",
                        55,
                        8
                      ]
                    ];
              }
              return React.createElement("div", {
                          id: "App"
                        }, ReasonReact.element(undefined, undefined, Nav.make(/* array */[])), React.createElement("div", {
                              className: "container"
                            }, tmp, React.createElement("div", {
                                  id: "Footer"
                                }, React.createElement("p", {
                                      className: "foot-tag"
                                    }, "Handcrafted with <3 and ReasonReact"))));
            }),
          /* initialState */(function (param) {
              return /* record */[/* route */mapUrlToRoute(ReasonReact.Router[/* dangerouslyGetInitialUrl */3](/* () */0))];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */reducer,
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.mapUrlToRoute = mapUrlToRoute;
exports.reducer = reducer;
exports.component = component;
exports.make = make;
/*  Not a pure module */
