// Generated by BUCKLESCRIPT VERSION 4.0.18, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var Project = require("../components/project");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var TuringGif = require("./turing.gif");
var BsaMapPng = require("../images/bsaMap.png");
var Destination_moon_16Jpg = require("../images/destination_moon_16.jpg");

var component = ReasonReact.reducerComponent("Work");

function make(_children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (param) {
              return React.createElement("div", {
                          className: "page",
                          id: "Work"
                        }, React.createElement("h1", {
                              className: "page-title"
                            }, "work"), React.createElement("div", {
                              className: "project-previews"
                            }, $$Array.of_list(List.map((function (project) {
                                        return ReasonReact.element(project[/* company */1], undefined, Project.make(project[/* image */0], project[/* company */1], project[/* role */2], project[/* description */3], project[/* achievements */4], project[/* website */5], project[/* location */6], project[/* startDate */7], project[/* endDate */8], project[/* highlights */9], /* array */[]));
                                      }), param[/* state */1][/* projects */0]))));
            }),
          /* initialState */(function (param) {
              return /* record */[/* projects : :: */[
                        /* record */[
                          /* image */BsaMapPng,
                          /* company */"BSA",
                          /* role */"full-stack dev",
                          /* description */"",
                          /* achievements : :: */[
                            "created custom components for cross-platform use while adhering to strong brand aesthetic",
                            /* :: */[
                              "effectively employed open source solutions",
                              /* :: */[
                                "sourced and cleaned a LOT of data",
                                /* [] */0
                              ]
                            ]
                          ],
                          /* website */"blacksocialists.us",
                          /* location */"US",
                          /* startDate */"Apr '19",
                          /* endDate */"Present",
                          /* highlights : :: */[
                            "ux design",
                            /* :: */[
                              "full stack development",
                              /* :: */[
                                "react",
                                /* :: */[
                                  "mapbox gl",
                                  /* :: */[
                                    "mapbox style api",
                                    /* :: */[
                                      "postgres",
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ],
                        /* :: */[
                          /* record */[
                            /* image */TuringGif,
                            /* company */"Turing",
                            /* role */"Founder",
                            /* description */"",
                            /* achievements : :: */[
                              "full stack development",
                              /* :: */[
                                "creative direction",
                                /* :: */[
                                  "branding design",
                                  /* [] */0
                                ]
                              ]
                            ],
                            /* website */"turing.nyc",
                            /* location */"NY, NY",
                            /* startDate */"Jan '19",
                            /* endDate */"Present",
                            /* highlights : :: */[
                              "full stack development",
                              /* :: */[
                                "reasonml",
                                /* :: */[
                                  "react",
                                  /* :: */[
                                    "bucklescript",
                                    /* :: */[
                                      "concept design",
                                      /* :: */[
                                        "branding design",
                                        /* :: */[
                                          "creative direction",
                                          /* :: */[
                                            "canvas api",
                                            /* :: */[
                                              "printmaking",
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ],
                          /* :: */[
                            /* record */[
                              /* image */"https://s3-media3.fl.yelpcdn.com/bphoto/If-8qLOWcIAV4WWyUgSGoQ/o.jpg",
                              /* company */"Fed",
                              /* role */"Co-Founder+CXO",
                              /* description */"",
                              /* achievements : :: */[
                                "end-to-end development of a complete user | product experience, from concept to branding design to UI to dining experience to post-use lifecycle design",
                                /* :: */[
                                  "full stack development of MVP using Node|Meteor|Mongo",
                                  /* :: */[
                                    "project management of further webdev",
                                    /* :: */[
                                      "creative direction of all styles, assets and content",
                                      /* :: */[
                                        "integrated SEM and analytics with Google Analytics",
                                        /* :: */[
                                          "integrated payments with Stripe API",
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ],
                              /* website */"getfednyc.com",
                              /* location */"Brooklyn, NY",
                              /* startDate */"Sept '16",
                              /* endDate */"Dec '18",
                              /* highlights : :: */[
                                "full stack development",
                                /* :: */[
                                  "node",
                                  /* :: */[
                                    "meteor",
                                    /* :: */[
                                      "mongoDB",
                                      /* :: */[
                                        "user experience design",
                                        /* :: */[
                                          "project management",
                                          /* :: */[
                                            "marketing strategy",
                                            /* :: */[
                                              "creative direction",
                                              /* :: */[
                                                "food photography",
                                                /* :: */[
                                                  "food styling",
                                                  /* :: */[
                                                    "packaging design",
                                                    /* :: */[
                                                      "logistics",
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ],
                            /* :: */[
                              /* record */[
                                /* image */Destination_moon_16Jpg,
                                /* company */"Destination Moon",
                                /* role */"Branding/Creative",
                                /* description */"",
                                /* achievements : :: */[
                                  "logo and branding design",
                                  /* :: */[
                                    "led art direction and asset creation",
                                    /* [] */0
                                  ]
                                ],
                                /* website */"destinationmoon.us",
                                /* location */"Greater NY",
                                /* startDate */"Apr '16",
                                /* endDate */"Jun '16",
                                /* highlights : :: */[
                                  "branding",
                                  /* :: */[
                                    "experience design",
                                    /* :: */[
                                      "art direction",
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ],
                              /* :: */[
                                /* record */[
                                  /* image */"http://myrtleavenue.org/wp-content/uploads/2014/10/Gnarly-Vines-shingle-blog-ready.jpg",
                                  /* company */"Gnarly Vines",
                                  /* role */"Operations Manager",
                                  /* description */"",
                                  /* achievements : :: */[
                                    "led content creation for website and email campaigns",
                                    /* :: */[
                                      "managed POS-website integration",
                                      /* :: */[
                                        "improved SEO through partnerships, schema tags, best practices",
                                        /* [] */0
                                      ]
                                    ]
                                  ],
                                  /* website */"gnarlyvines.com",
                                  /* location */"Brooklyn, NY",
                                  /* startDate */"'13",
                                  /* endDate */"'14",
                                  /* highlights : :: */[
                                    "oenology",
                                    /* :: */[
                                      "pos & ecommerce",
                                      /* :: */[
                                        "sales strategy",
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ],
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (param, param$1) {
              return /* NoUpdate */0;
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
